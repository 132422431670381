import Moment from "moment";

export const mmFormatStr = "YYYY-MM-DD HH:mm";

export function tsFormat(ts) {
  return Moment(ts).format("YYYY-MM-DD HH:mm:ss");
}
export function hoursFormat(ts) {
  return Moment(ts).format("YYYY-MM-DD HH:mm");
}

export function mmFormat(ts) {
  return Moment(ts).format(mmFormatStr);
}

export function dtFormat(dt) {
  return Moment(dt).format("YYYY-MM-DD");
}

/**
 * 数字转成汉字
 * @params num === 要转换的数字
 * @return 汉字
 * */
export function toChinesNum(num) {
  let changeNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  let unit = ["", "十", "百", "千", "万"];
  num = parseInt(num);
  let getWan = temp => {
    let strArr = temp
      .toString()
      .split("")
      .reverse();
    let newNum = "";
    let newArr = [];
    strArr.forEach((item, index) => {
      newArr.unshift(
        item === "0" ? changeNum[item] : changeNum[item] + unit[index]
      );
    });
    let numArr = [];
    newArr.forEach((m, n) => {
      if (m !== "零") numArr.push(n);
    });
    if (newArr.length > 1) {
      newArr.forEach((m, n) => {
        if (newArr[newArr.length - 1] === "零") {
          if (n <= numArr[numArr.length - 1]) {
            newNum += m;
          }
        } else {
          newNum += m;
        }
      });
    } else {
      newNum = newArr[0];
    }

    return newNum;
  };
  let overWan = Math.floor(num / 10000);
  let noWan = num % 10000;
  if (noWan.toString().length < 4) {
    noWan = "0" + noWan;
  }
  return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
}

export function msFormat(ts) {
  return Moment(ts).format("HH:mm:ss");
}
