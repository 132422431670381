import { apiClient, downloadApiClient } from "./HttpService";

// 档案库列表
export function fetchArchivesList(params) {
  return apiClient.get(
    `/archives?distributorType=${params.distributorType}&corporationName=${params.corporationName}&pageNum=${params.pageNum}&pageSize=${params.pageSize}&incorporateStatus=${params.incorporateStatus}&registeredAreaCode=${params.registeredAreaCode}&createTimeStart=${params.createTimeStart}&createTimeEnd=${params.createTimeEnd}`
  );
}

// 档案库导出
export function exportArchivesList(params) {
  return downloadApiClient.get(
    `/archives/export?distributorType=${params.distributorType}&corporationName=${params.corporationName}&incorporateStatus=${params.incorporateStatus}&registeredAreaCode=${params.registeredAreaCode}&createTimeStart=${params.createTimeStart}&createTimeEnd=${params.createTimeEnd}`
  );
}

// 创建档案库
export function addArchives(params) {
  return apiClient.post("/archives", params);
}

// 修改档案库
export function modifyArchives(params) {
  return apiClient.put(`/archives/${params.distributorId}`, params);
}

// 增加机构类型--> 新建档案库
export function newAddArchives(corporationId, params) {
  return apiClient.post(`/archives/distributorType/${corporationId}`, params);
}
// 校验公司是否可新增某机构类型
export function whetherCouldAddArchives(corporationId, distributorType) {
  return apiClient.get(
    `/archives/distributor-type-check/${corporationId}/${distributorType}`
  );
}

// 获取机构的管辖区域
export function fetchArchivesArea(id) {
  return apiClient.get(`/archives/jurisdiction?corporationId=${id}`);
}

// 获取所有的服务中心
export function fetchAllServiceCenter() {
  return apiClient.get("/archives/distributor?distributorType=serviceCenter");
}

// 获取所有的运营中心
export function fetchAllOperationCenter() {
  return apiClient.get(
    "/archives/distributor/?distributorType=operationCenter"
  );
}

// 获取所有的货栈
export function fetchAllWarehouse() {
  return apiClient.get("/archives/distributor/?distributorType=warehouse");
}

// 获取所有的货栈
// export function fetchAllWarehouse(corporationId) {
//   return apiClient.get(`/archives/warehouse?corporationId=${corporationId}`);
// }

// 获取机构档案详情
export function fetchArchivesDetail(distributorType, id) {
  return apiClient.get(`/archives/${id}?distributorType=${distributorType}`);
}

// 删除档案
export function deleteArchives(corporationId) {
  return apiClient.delete(`/archives/${corporationId}`);
}

// 组织管理树形结构
export function fetchOrganizationManagementTree() {
  return apiClient.get("/archives/organization-management-tree");
}

// 查看法人或经营人是否已认证
export function fetchUserAuthenticated(idCardNo, name, userType) {
  return apiClient.get(
    `/archives/user-authenticated-check?idCardNo=${idCardNo}&name=${name}&userType=${userType}`
  );
}

//获取指定经营地址的指定机构
export function fetchExecutor(
  executorProvinceCode,
  executorCityCode,
  executorCountyCode
) {
  return apiClient.get(
    `/archives/corporation-by-area?distributorType=operationCenter&executorCityCode=${executorCityCode}&executorCountyCode=${executorCountyCode}&executorProvinceCode=${executorProvinceCode}`
  );
}

// 查询银行
export function searchBank(params) {
  return apiClient.get(
    `/bank?keyword=${params.keyword}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 查询支行
export function searchBranch(params) {
  return apiClient.get(
    `/bank/branch?keyword=${params.keyword}&bankNo=${params.bankNo}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

//查询银行卡所属信息接口
export function onFetchCardNo(cardNo) {
  return apiClient.get(`/bank-card/${cardNo}`);
}

//银行卡绑定申请接口
export function onApplyCard(params) {
  return apiClient.post(`/bank-card/apply`, params);
}

//银行卡绑定确认接口
export function onReportCard(params) {
  return apiClient.post(`/bank-card/confirm`, params);
}

// 根据卡号获取银行卡信息
export function fetchBankCardInfoByAccount(cardNo) {
  return apiClient.get(`/bank-card/info/${cardNo}`);
}

// 银行卡是否存在
export function judgeAccountNumberIsExist(accountNumber) {
  return apiClient.get(`/bankcard/${accountNumber}`);
}
