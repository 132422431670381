import { apiClient } from "./HttpService";

// 原厂商新增（绑定）经销商
export function addBindDealer(factoryId, params) {
  return apiClient.post(`/factories/${factoryId}/dealers`, params);
}
// 获取行政区树
export function loadArea() {
  return apiClient.get("/areas/full-areas");
}

// 查询银行
export function searchBank(params) {
  return apiClient.get(
    `/bank?keyword=${params.keyword}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 查询支行
export function searchSubbranch(params) {
  return apiClient.get(
    `/bank/branch?keyword=${params.keyword}&bankNo=${params.bankNo}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}
// 根据code查询经销商信息
export function loadDetail(orgCode) {
  return apiClient.get(`/dealers?orgCode=${orgCode}`);
}

// 查询原厂经销商列表
export function fetchDealerList(params) {
  return apiClient.get(
    `/factories/${params.factoryId}/dealers?auditStatus=${params.auditStatus}&cooperateStatus=${params.cooperateStatus}&dealerName=${params.dealerName}&legalPerName=${params.legalPerName}&orgCode=${params.orgCode}&pageNum=${params.pageNum}&pageSize=${params.pageSize}&personName=${params.personName}&personTel=${params.personTel}`
  );
}

// 查询经销商详情
export function fetchDealerDetail(factoryVenuId) {
  return apiClient.get(`/factories/dealers/${factoryVenuId}`);
}

// 修改经销商联系人信息
export function modifyContact(params) {
  return apiClient.put("/dealers/contact", params);
}

// 修改经销商状态-中止、恢复
export function modifyDealerStatus(params) {
  return apiClient.put("/factories/cooperate-status", params);
}

// 获取指定类型渠道商
export function fetchSpecifyTypeCorporation(distributorType) {
  return apiClient.get(
    `/archives/distributor?distributorType=${distributorType}`
  );
}

// 取省市行政区
export function fetchProvinceAndCityArea() {
  return apiClient.get("/areas/provinceAndCityAreas");
}
