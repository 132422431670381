import { apiClient } from "./HttpService";

// 价值包类型列表
export function fetchPolicy(name, allowSet) {
  return apiClient.get(
    `/strategy/policyType?policyTypeName=${name}&allowSet=${allowSet}`
  );
}

// 价值包类型列表
export function fetchCanChoosePolicy(id) {
  return apiClient.get(`/strategy/policyType/exclude/${id}`);
}

// 修改策略
export function modifyPolicy(params) {
  return apiClient.patch("/strategy/policyType", params);
}

// 策略列表
export function fetchStrategyList(params) {
  return apiClient.get(
    `/strategy/pageList?corporationId=${
      params.corporationId ? params.corporationId : ""
    }&endTime=${params.endTime}&pageNum=${params.pageNum}&pageSize=${
      params.pageSize
    }&startTime=${params.startTime}&status=${
      params.status ? params.status : ""
    }&apply=${params.apply ? params.apply : false}&strategyName=${
      params.strategyName ? params.strategyName : ""
    }&areaCode=${params.areaCode}`
  );
}
// 修改策略
export function strategyInitPolicy() {
  return apiClient.get("/strategy/initPolicy");
}
// 策略列表 - 价值包审核
export function fetchStrategyApplyList(params) {
  return apiClient.get(
    `/strategy/pageList/apply?corporationId=${
      params.corporationId ? params.corporationId : ""
    }&endTime=${params.endTime}&pageNum=${params.pageNum}&pageSize=${
      params.pageSize
    }&startTime=${params.startTime}&status=${
      params.status ? params.status : ""
    }&apply=${params.apply ? params.apply : false}&strategyName=${
      params.strategyName ? params.strategyName : ""
    }&areaCode=${params.areaCode}`
  );
}

// 删除策略
export function deleteStrategy(id) {
  return apiClient.delete(`/strategy/${id} `);
}

// 复制策略
export function copyStrategy(id) {
  return apiClient.post(`/strategy/copy/${id} `);
}
// 策略新增
export function addPolicy(params) {
  return apiClient.post("/strategy", params);
}

// 策略新增
export function modifyStrategyOfPolicy(params) {
  return apiClient.put("/strategy", params);
}

// 策略详情
export function fetchPolicyDetail(id) {
  return apiClient.get(`/strategy/${id}`);
}

// 创建策略所属价值包
export function addPolicyValue(params) {
  return apiClient.post("/strategy/policy", params);
}

// 修改策略所属价值包
export function modifyPolicyValue(params) {
  return apiClient.put("/strategy/policy", params);
}

// 策略所属价值包列表
export function fetchStrategyOfPolicyList(params) {
  return apiClient.get(
    `/strategy/policy/pageList?strategyId=${params.strategyId}&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}

// 查看策略所属价值包详情
export function fetchStrategyOfPolicyDetail(id) {
  return apiClient.get(`/strategy/policy/${id}`);
}

// 删除策略所属价值包
export function deleteStrategyOfPolicy(id) {
  return apiClient.delete(`/strategy/policy/${id}`);
}

// 产品和Sku信息列表（嵌套树
export function fetchProductsAndSkuTree() {
  return apiClient.get("/productsAndSku");
}

// 产品和Sku信息列表（嵌套树) (只包含整车)
export function fetchVehicleProductsAndSkuTree() {
  return apiClient.get("/productsAndSkuVehicle");
}

// 通过价值包申报
export function passValueReport(strategyId, reason) {
  return apiClient.patch(
    `/strategy/corporation/pass/${strategyId}/?reason=${reason}`
  );
}

// 不通过价值包申报
export function unpassValueReport(strategyId, reason) {
  return apiClient.patch(
    `/strategy/corporation/refuse/${strategyId}/?reason=${reason}`
  );
}

// 查看价值包审核日志
export function fetchValueBagLog(strategyId) {
  return apiClient.get(`/strategy/logs/${strategyId}`);
}

// 分页查询机构额度
export function corporationCredit(params) {
  return apiClient.get(
    `/corporation/credit?corporationName=${
      params.corporationName ? params.corporationName : ""
    }&pageNum=${params.pageNum}&pageSize=${params.pageSize}`
  );
}
//新增渠道机构额度
export function corporationCreditAdd(params) {
  return apiClient.post(`/corporation/credit`, params);
}
//编辑渠道机构额度
export function corporationCreditEdit(corporationId, params) {
  return apiClient.patch(`/corporation/credit/${corporationId}`, params);
}
//获取机构信息列表
export function organizationInfoList(distributorCode) {
  return apiClient.get(`/corporation/organizationInfoList/${distributorCode}`);
}

// 根据地区获取机构信息列表
export function organizationInfoListByAreaCode(params) {
  return apiClient.get(
    `/corporation/corporation-info?areaCode=${params.areaCode}`
  );
}
//获取机构信息列表
export function corporationDetail(distributorId) {
  return apiClient.get(`/corporation/${distributorId}`);
}
//分期配置分页列表
export function installmentConfig(params) {
  return apiClient.get(
    `/installmentConfig?pageSize=${params.pageSize}&pageNum=${params.pageNum}`
  );
}
//新增渠道机构额度
export function installmentConfigAdd(params) {
  return apiClient.post(`/installmentConfig`, params);
}
//新增渠道机构额度
export function installmentConfigDetail(installmentConfigId) {
  return apiClient.get(`/installmentConfig/${installmentConfigId}`);
}
//编辑渠道机构额度
export function installmentConfigEdit(params) {
  return apiClient.patch(`/installmentConfig`, params);
}
//产品设置列表
export function productConfig(params) {
  return apiClient.get(
    `/product/config?pageSize=${params.pageSize}&pageNum=${params.pageNum}`
  );
}
//新增修改产品设置
export function productConfigSet(params) {
  return apiClient.post(`/product/config`, params);
}
//删除产品设置
export function productConfigDelete(productId) {
  return apiClient.delete(`/product/config/${productId}`);
}
//查询租赁分润配置列表
export function leaseList(params) {
  return apiClient.get(
    `/lease/manage?pageSize=${params.pageSize}&pageNum=${params.pageNum}`
  );
}
//保存租赁分润配置
export function leaseAdd(params) {
  return apiClient.post(`/lease/manage`, params);
}
//更新租赁分润配置
export function leaseEdit(params) {
  return apiClient.patch(`/lease/manage`, params);
}
//删除租赁分润配置
export function leaseDelete(shareConfigId) {
  return apiClient.delete(`/lease/manage/${shareConfigId}`);
}
//查询平台费配置列表
export function platformList(params) {
  return apiClient.get(
    `/platform-price/manage?pageSize=${params.pageSize}&pageNum=${params.pageNum}`
  );
}
//保存平台费配置
export function platformAdd(params) {
  return apiClient.post(`/platform-price/manage`, params);
}
//更新平台费配置
export function platformEdit(params) {
  return apiClient.patch(`/platform-price/manage`, params);
}
//删除平台费配置
export function platformDelete(configId) {
  return apiClient.delete(`/platform-price/manage/${configId}`);
}

// ------- 账号管理/角色管理/权限管理  相关api------

//查询平台账号列表
export function getUserList(params) {
  return apiClient.get(
    `/personnel?pageSize=${params.pageSize}&pageNum=${params.pageNum}&realName=${params.realName}&userName=${params.userName}`
  );
}

// 新建账号
export function addUser(params) {
  return apiClient.post(`/personnel`, params);
}

// 修改账号
export function editUser(personnelId, params) {
  return apiClient.put(`/personnel/${personnelId}`, params);
}

// 删除账号
export function deleteUser(userId) {
  return apiClient.delete(`/personnel/${userId}`);
}

// 账号详情
export function getUserDetail(personnelId) {
  return apiClient.get(`/personnel/${personnelId}`);
}

// 总部角色列表
export function getAvailableRoles(params) {
  return apiClient.get(
    `/roles/option?distributorTypes=${params.distributorTypes}`
  );
}

//查询平台角色列表
export function getRoleList(params) {
  return apiClient.get(
    `/roles?roleName=${params.roleName}&pageSize=${params.pageSize}&pageNum=${params.pageNum}`
  );
}

// 新增角色
export function addRole(params) {
  return apiClient.post(`/roles`, params);
}

// 修改角色
export function editRole(params) {
  return apiClient.put(`/roles/${params.roleId}`, params);
}

// 删除角色
export function deleteRole(roleId) {
  return apiClient.delete(`/roles/${roleId}`);
}
// 根据角色查询关联的员工信息
export function usePersonnel(sysRoleId) {
  return apiClient.get(`/roles/usePersonnel/${sysRoleId}`);
}
// 权限列表
export function getPrivilegeList(params) {
  return apiClient.get(
    `/privileges?roleId=${params.roleId}&terminalType=${params.terminalType}`
  );
}

// 保存授权
export function editPrivileges(roleId, params) {
  return apiClient.post(`/privileges/${roleId}`, params);
}

// 获取当前登录用户的授权模块
export function getCurrentUserPermissions() {
  return apiClient.get("/menus");
}
// 运营总部查询站点列表
export function operationHeadquartersSiteInfo(params) {
  return apiClient.get(
    `/corporation/sties/operation-headquarters/site-info?siteName=${
      params.siteName ? params.siteName : ""
    }&corporationId=${
      params.corporationId ? params.corporationId : ""
    }&siteAdminName=${params.siteAdminName ? params.siteAdminName : ""}&phone=${
      params.phone ? params.phone : ""
    }&startTime=${params.startTime ? params.startTime : ""}&endTime=${
      params.endTime ? params.endTime : ""
    }&status=${params.status ? params.status : ""}&pageSize=${
      params.pageSize
    }&pageNum=${params.pageNum}`
  );
}
// 运营总部查询站点详情
export function operationHeadquartersSiteInfoDetail(siteId) {
  return apiClient.get(
    `/corporation/sties/operation-headquarters/site-info/${siteId}`
  );
}
// 价格预览
export function strategYpreviewPolicy(params) {
  return apiClient.post(`/strategy/previewPolicy`, params);
}
